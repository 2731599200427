import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Card from '@mui/joy/Card';
import FoldableInput from '../../../inputs/FoldableInput';
import Typography from '@mui/joy/Typography';
import EditIcon from '@mui/icons-material/Edit';
import Divider from '@mui/joy/Divider';
import Checkbox from '@mui/joy/Checkbox';
import Sheet from '@mui/joy/Sheet';
import SearchDropdown from '../../../inputs/SearchDropdown';
import ImageCapture from '../../../inputs/ImageCapture'; // Import the new component
import axios from 'axios';
import domain from "../../../../util/domain";
import CapturedLocation from '../../../inputs/CapturedLocation';


export default function HODEdit({ details }) {
    const navigate = useNavigate();
    const location = useLocation();
    const { hodDetails } = location.state || {};
    console.log("HODEdit: hodDetails: ", hodDetails);
    const visitDetails_id = hodDetails.visitDetails_id;
    const [hodname, setHodname] = useState(hodDetails.hodname || hodDetails.tponame);
    const [hod_email, setHod_email] = useState(hodDetails.hod_email || hodDetails.tpo_email);
    const [hod_mobile, setHod_mobile] = useState(hodDetails.hod_mobile || hodDetails.tpo_mobile);
    const [isStudentDataShared, setIsStudentDataShared] = useState(hodDetails.isStudentDataShared);
    const [remarks, setRemarks] = useState(hodDetails.remarks);
    const [branch, setBranch] = useState(hodDetails.branch);
    const [photo_url] = useState(hodDetails.photo_url);
    const [notice_url] = useState(hodDetails.notice_url);
    const [capturedPhoto, setCapturedPhoto] = useState(null);
    const [capturedNoticePhoto, setCapturedNoticePhoto] = useState(null);
    const [geoLocation, setGeoLocation] = useState({ lat: hodDetails.latitude, lon: hodDetails.longitude });

    let hod = (hodDetails.hodname) ? true : false;
    let hodTxt = (hod) ? "HOD" : "TPO";

    const handleNoticeCapture = (photo) => {
        setCapturedNoticePhoto(photo);
    }
    const handleCapture = (photo) => {
        setCapturedPhoto(photo);
    };

    const updateLocation = (location) => {
        console.log("location: ", location);
        setGeoLocation(location);
    };

    const batchCanged = (selectedOption) => {
        console.log("Selected Option: ", selectedOption);
        setBranch(selectedOption.branch);
    }
    const cancelAction = (e) => {
        e.preventDefault();
        navigate(-1);
    }
    const updateHOD = (e) => {
        e.preventDefault();
        if (hod) {
            axios.post(`${domain}/hods/edit/${hodDetails.id}`, {
                hodname,
                hod_email,
                hod_mobile,
                isStudentDataShared,
                remarks,
                visitDetails_id,
                branch,
                longitude: geoLocation.lon,
                latitude: geoLocation.lat,
                newPhoto: capturedPhoto,
                photo_url,
                newNoticePhoto: capturedNoticePhoto,
                notice_url

            })
                .then((response) => {
                    console.log("HODEdit: updateHOD: response: ", response.data);
                })
                .catch((error) => {
                    console.error("HODEdit: updateHOD: error: ", error);
                });
        } else {
            // Update TPO

            axios.post(`${domain}/tpos/edit/${hodDetails.id}`, {
                tponame: hodname,
                tpo_email: hod_email,
                tpo_mobile: hod_mobile,
                isStudentDataShared,
                remarks,
                visitDetails_id,
                branch,
                longitude: geoLocation.lon,
                latitude: geoLocation.lat,
                newPhoto: capturedPhoto,
                photo_url,
                newNoticePhoto: capturedNoticePhoto,
                notice_url
            })
                .then((response) => {
                    console.log("HODEdit: updateHOD: response: ", response.data);
                })
                .catch((error) => {
                    console.error("HODEdit: updateHOD: error: ", error);
                });
        }
        navigate(-1);
    }
    return (
        <form>
            <Card>
                <Typography level="title-lg" startDecorator={<EditIcon />}>
                    {`Edit ${hodTxt} Details`}
                </Typography>
                <Divider inset="none" />

                <Sheet sx={{ height: '44px', paddingBottom: '10px' }}>
                    <SearchDropdown defaultOption={{ branch, "id": hodDetails.branch_id }} onBatchChange={batchCanged} />
                </Sheet>
                <FoldableInput inputType='text' labelText={`${hodTxt} Name`} placeHolderText={`${hodTxt} Name`} value={hodname} onChange={(e) => setHodname(e.target.value)} />
                <FoldableInput inputType='phone' labelText={`${hodTxt} Mobile`} placeHolderText={`${hodTxt} Mobile`} value={hod_mobile} onChange={(e) => setHod_mobile(e.target.value)} />
                <FoldableInput inputType='email' labelText={`${hodTxt} E-Mail`} placeHolderText={`${hodTxt} E-Mail`} value={hod_email} onChange={(e) => setHod_email(e.target.value)} />
                <FoldableInput inputType='textarea' labelText={`${hodTxt} Remarks`} placeHolderText={`${hodTxt} Remarks`} value={remarks} onChange={(e) => setRemarks(e.target.value)} />
                <Sheet variant="outlined" sx={{ height: '44px' }}>
                    <Checkbox overlay label="Is Student Data Shared?"
                        sx={{ alignContent: 'center', paddingLeft: '10px', marginTop: '13px' }}
                        checked={(isStudentDataShared === 0) ? false : true}
                        onChange={(e) => {
                            setIsStudentDataShared((e.target.checked) ? 1 : 0);
                        }}
                    />
                </Sheet>
                <CapturedLocation onUpdateLocation={updateLocation} />
                {/* Image Capture Component */}
                <ImageCapture title={`${hodTxt} Photo`} onCapture={handleCapture} photoUrl={photo_url} />

                <ImageCapture title={`${hodTxt} HOD Notice Board`} onCapture={handleNoticeCapture} photoUrl={notice_url} />

                <button type="button" onClick={cancelAction}>Cancel</button>
                <button type="submit" onClick={updateHOD}>Save</button>
            </Card>
        </form>
    );
}