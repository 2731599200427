import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./components/auth/Login";
import Home from "./components/home/Home";
import VisitDetails from "./components/home/visits/VisitDetails";
import NavBar from "./components/misc/NavBar";
import VisitHodTpoDetails from "./components/home/visits/details/VisitHodTpoDetails";
import HODEdit from "./components/home/visits/details/HODEdit";
import AddHOD from "./components/home/visits/details/AddHOD";
import AddTPO from "./components/home/visits/details/AddTPO";
import BackButton from "./components/misc/BackButton";
import AddVisit from "./components/home/visits/AddVisit";
import ChangePassword from "./components/auth/ChangePassword";
import CollegeSearch from "./components/home/CollegeSearch";
import CollegeHodTpoDetails from "./components/home/CollegeHodTpoDetails";
function Router() {

  return (
    <BrowserRouter>
      <NavBar
        title="College Visits"
        leftButton={<BackButton />}
        rightButton={<button onClick={() => alert('More clicked')}>More</button>}
      />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="/visits" element={<VisitDetails />} />
        <Route path="/visits/add" element={<AddVisit />} />
        <Route path="/visits/details" element={<VisitHodTpoDetails />} />
        <Route path="/visits/details/edit" element={<HODEdit />} />
        <Route path="/visits/details/addhod" element={<AddHOD />} />
        <Route path="/visits/details/addtpo" element={<AddTPO />} />
        <Route path="/search" element={<CollegeSearch />} />
        <Route path="/college" element={<CollegeHodTpoDetails />} />

      </Routes>
    </BrowserRouter>
  );
}

export default Router;
