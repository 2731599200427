import React, { useContext, useState } from 'react';
import './NavBar.css'; // Import the CSS for styling
import UserContext from "../../context/UserContext";
import IconButton from '@mui/joy/IconButton';
import Menu from '@mui/joy/Menu';
import MenuItem from '@mui/joy/MenuItem';
import MoreVert from '@mui/icons-material/MoreVert';
import { useNavigate } from 'react-router-dom';

const NavBar = ({ title, leftButton }) => {
  const { user, logoutUser } = useContext(UserContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleChangePassword = () => {
    handleMenuClose();
    navigate('/change-password');
  };

  const handleLogout = () => {
    handleMenuClose();
    logoutUser();
    navigate('/');
  };

  return (
    <div className="nav-bar">
      {leftButton && <div className="nav-left">{leftButton}</div>}

      <div className="nav-title">{title}</div>

      {user && (
        <div className="nav-right">
          <IconButton onClick={handleMenuOpen}>
            <MoreVert />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={handleChangePassword}>Change Password</MenuItem>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>
        </div>
      )}
    </div>
  );
};

export default NavBar;